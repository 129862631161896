import React from "react";
import logo from "../assets/logo.svg";
import "./MainPage.css";
import { CgMenuMotion } from "react-icons/cg";
import apple from "../assets/apple.png";
import google from "../assets/google.png";
import { FaInstagram } from "react-icons/fa6";
import { RxTwitterLogo } from "react-icons/rx";
import { TiSocialFacebook } from "react-icons/ti";
import topimg from "../assets/topimg.png";
import hdr from "../assets/hdrbackground.svg";
import background from "../assets/background.svg";
import imagetwo from "../assets/imagetwo1.png";
import frame from "../assets/frame.png";
import preview from "../assets/preview.png";
import cup from "../assets/cup.png";
import textimg from "../assets/textimg.svg";
import Countdown from "react-countdown";
import { Box, Typography, Stack } from "@mui/material";

const MainPage = () => {

  const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span style={{ fontSize: '24px', color: '#fff' }}>Time's up!</span>;
    } else {
      console.log(days)
      const totalHours = days * 24 + hours;
      return (
        <div style={{display: "flex", flexDirection: "column"}}>
          <span style={{ fontSize: '16px', fontWeight: "bold", color: '#fff' }}>
            {String(totalHours).padStart(2, '0')} : {String(minutes).padStart(2, '0')} : {String(seconds).padStart(2, '0')}
          </span>
          <span style={{ fontSize: '4px', fontWeight: "bold", color: '#fff' }}>
          <span style={{marginLeft: "6px"}}/> Hours   <span style={{marginRight: "18px"}}/>Minutes  <span style={{marginRight: "4px"}}/>   <span style={{marginRight: "14px"}}/>   Seconds
          </span>
        </div>
      );
    }
  };

  const currentYear = new Date().getFullYear();
  const targetDate = new Date(currentYear, 6, 10, 0, 0, 0);

  return (
    <>
      <div style={{marginTop:"140px"}} className="desktop-main">
        <h2 className="display-desktop">
          Kindly use the MOBILE VIEW to access the website Thank you,
          <br /> Have a great day.
          {/* <span>😊</span> */}
        </h2>
      </div>
      <div className="main-page">
        <img src={hdr} alt="reunirts" className="hdr-img" />
        
        <div className="main-hdr">
          <img src={logo} alt="reunirts" className="page-logo" />
          {/* <CgMenuMotion className="main-menu" /> */}
          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <div style={{width: 80, textAlign: "end", marginRight: "8px"}}>
            <p className="main-text-min" >Launch Soon</p>
            
          </div>

          <Countdown date={targetDate} renderer={renderer}/>
          </div>
        </div>
        <img src={topimg} alt="reunirts" className="image-one" />
        {/* <div className="background-main"> */}
        <img src={background} alt="reunirts" className="background" />
        {/* </div> */}
        <div className="section-two">
          <p className="main-text1">
            The wait is almost over!
            <br />
            <span className="main-text2">Our app is coming soon.</span>
          </p>
          <span className="main-desc">
          Nutrelis Dentist is a highly recommended and loved dentist brand in India. It became the preferred brand with its quality focus attitude.
          </span>
          <img src={textimg} alt="text" className="textbox" />

          {/* <div className="gradientBorderBox">
          <p className="gradientBorder">The wait is almost over!<b/>
          Our app is coming soon.</p>
        </div> */}
          <div className="downloads">
            <p className="download-ttl">Available Soon On</p>
            <div className="download-main">
              <img src={apple} alt="apple" className="applestore" />
              <img src={google} alt="google" className="googleplay" />
            </div>
          </div>

          <div className="section-three">
            <p className="title-text1">
              Putting the {""}
              <span className="title-text2">Radiance</span>
              <span className="title-text3">Back in Your Smile.</span>
            </p>
            <span className="app-desc">
              The app is seamlessly integrated with the appointment scheduling
              system, allowing users to easily schedule and manage appointments
              with their dentist.
            </span>
          </div>
        </div>
        <Stack

          alignItems={"center"}
        >
          {/* <img src={imagetwo} alt="reunirts" className="imgtwo" /> */}
          <Box
            sx={{
              backgroundImage: `url('${frame}')`,
              backgroundSize: '100% 100%',
              margin: "0 8px",
              padding: "0px 10px 34px 10px"
            }}
          >
            <img src={preview} alt="reunirts" className="imgtwo" />
            <Stack direction={"row"} spacing={1} sx={{margin: "0 8px"}}>
              <Stack alignItems={"center"} spacing={1} p={0.5}>
                <img src={cup} alt="reunirts" width={"30px"}/>
                <Typography sx={{fontSize: 14, fontWeight: "bold", color: "#252525"}}>Quick Booking</Typography>
                <Typography sx={{fontSize: 9, color: "#252525"}}>Why to wait to book your preferred dentist ? Now anyone can book their loved dentist, consult them and become well.</Typography>
              </Stack>

              <Stack  alignItems={"center"} spacing={1} p={0.5}>
                <img src={cup} alt="reunirts" width={"30px"} />
                <Typography sx={{fontSize: 14, fontWeight: "bold", color: "#252525"}}>Quality Focused</Typography>
                <Typography sx={{fontSize: 9, color: "#252525"}}>3 Stage Monitoring and Tracking Progress of the patient made the brand what it is today. Patient can be assured of getting well.</Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>

        <span className="footer-main">
          <span className="footer-logo">
            <FaInstagram className="insta" />
            <RxTwitterLogo className="twitter" />
            <TiSocialFacebook className="facebk" />
          </span>
          <span className="footer">
            <span className="terms">Privacy Poilcy</span>
            <span className="terms">Terms & Conditions</span>
            <span className="terms">Help</span>
          </span>
        </span>
      </div>
    </>
  );
};

export default MainPage;
